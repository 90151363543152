import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { reactive, ref } from "vue";
import LoginForm from "./LoginForm.vue";
import { createAuthService } from "@ts/Login/auth_service";
import GoogleRecaptcha from "@components/Core/GoogleRecaptcha.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'LoginFormHandler',
    props: {
        recaptchaKey: {
            type: String,
            required: true,
        },
        rememberMe: {
            type: Boolean,
            default: false,
        },
        forgotPasswordHref: {
            type: String,
            default: '',
        },
        email: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        buttonAboveForgotPass: {
            type: Boolean,
            default: false
        },
        isWebview: {
            type: Boolean,
            default: false,
        },
        coloredBackground: {
            type: Boolean,
            default: false
        },
        t: {
            type: Object,
            default: () => ({
                emailLabel: 'Email',
                passwordLabel: 'Password',
                rememberLabel: 'Remember Me',
                forgotPasswordText: 'Forgot Password?',
                loginViaEmailText: 'Log in via email link',
                loginText: 'Log In',
                useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
                abcCapitalized: 'ABC',
                abcLowerCase: 'abc',
                numeric: '123',
                symbols: '!@%',
                show: 'Show',
                hide: 'Hide',
                loginError: 'An internal error occurred. Please try again.'
            })
        },
    },
    emits: ['successful', 'failure', 'reset-error'],
    setup(__props, { emit: __emit }) {
        const state = reactive({
            user: {},
            loading: true,
        });
        const recaptcha = ref(null);
        const props = __props;
        const emit = __emit;
        const authService = createAuthService();
        const startLoading = () => state.loading = true;
        const stopLoading = () => state.loading = false;
        const handleSubmit = async (token) => {
            emit('reset-error');
            startLoading();
            try {
                const response = await authService.login(state.user, token);
                emit('successful', response);
            }
            catch (err) {
                stopLoading();
                const error = err?.error ?? props.t.loginError;
                emit('failure', error);
            }
        };
        const onBeforeSubmit = (user) => {
            if (!recaptcha.value) {
                throw new Error('Google Recaptcha was not loaded.');
            }
            startLoading();
            state.user = user;
            recaptcha
                .value
                .execute()
                .catch((error) => console.error(error))
                .finally(() => stopLoading());
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(LoginForm, {
                t: props.t,
                disabled: props.disabled || state.loading,
                "forgot-password-href": props.forgotPasswordHref,
                "remember-me": props.rememberMe,
                email: props.email,
                "button-above-forgot-pass": props.buttonAboveForgotPass,
                "is-webview": props.isWebview,
                "colored-background": props.coloredBackground,
                onSubmit: onBeforeSubmit
            }, {
                default: _withCtx(() => [
                    _createVNode(GoogleRecaptcha, {
                        ref_key: "recaptcha",
                        ref: recaptcha,
                        "site-key": props.recaptchaKey,
                        onLoaded: stopLoading,
                        onCanceled: stopLoading,
                        onFailure: _cache[0] || (_cache[0] = (error) => _ctx.$emit('failure', error)),
                        onSuccessful: handleSubmit
                    }, null, 8, ["site-key"])
                ]),
                _: 1
            }, 8, ["t", "disabled", "forgot-password-href", "remember-me", "email", "button-above-forgot-pass", "is-webview", "colored-background"]));
        };
    }
});
