import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "login-form__actions-container" };
const _hoisted_2 = ["href"];
const _hoisted_3 = { key: 0 };
const _hoisted_4 = {
    key: 1,
    class: "login-form__signup-container"
};
const _hoisted_5 = { class: "z-b4 login-form__signup-container__forgot-password-text" };
const _hoisted_6 = ["href"];
const _hoisted_7 = { key: 2 };
const _hoisted_8 = {
    key: 3,
    class: "z-b4 f-inter send-me-a-link"
};
import { reactive } from 'vue';
import ZButton from "@components/Core/ZButton.vue";
import ZInput from '@components/Core/ZInput.vue';
import FormCheckbox from '@components/Core/FormCheckbox.vue';
import PasswordInput from '@components/Login/PasswordInput.vue';
import { theme } from '@ts/Util/theme';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LoginForm',
    props: {
        t: {
            type: Object,
            default: () => ({
                emailLabel: 'Email',
                passwordLabel: 'Password',
                rememberLabel: 'Remember Me',
                forgotPasswordText: 'Forgot Password?',
                loginViaEmailText: 'Log in via email link',
                loginText: 'Log In',
                useAtLeastTwo: 'Use at least 2 of the following to create a strong password',
                abcCapitalized: 'ABC',
                abcLowerCase: 'abc',
                numeric: '123',
                symbols: '!@%',
                show: 'Show',
                hide: 'Hide',
                tryingToLogIn: 'Trying to log in using Google, Facebook or Apple?',
                sendMeALink: 'Send me a link',
            })
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        rememberMe: {
            type: Boolean,
            default: false,
        },
        email: {
            type: String,
            default: '',
        },
        forgotPasswordHref: {
            type: String,
            default: '',
        },
        buttonAboveForgotPass: {
            type: Boolean,
            default: false
        },
        isWebview: {
            type: Boolean,
            default: false,
        },
        coloredBackground: {
            type: Boolean,
            default: false
        },
    },
    emits: ['submit'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const state = reactive({
            remember: false,
            email: props.email ?? '',
            password: '',
        });
        const emit = __emit;
        const rememberToggle = () => state.remember = !state.remember;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("form", {
                ref: "login",
                class: _normalizeClass(['login-form__form', { 'colored-background': props.coloredBackground }]),
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event) => (emit('submit', state)), ["prevent"]))
            }, [
                _createVNode(ZInput, {
                    "model-value": state.email,
                    label: props.t.emailLabel,
                    disabled: props.disabled,
                    autocomplete: "username",
                    name: "username",
                    type: "text",
                    required: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => state.email = value)
                }, null, 8, ["model-value", "label", "disabled"]),
                _createVNode(PasswordInput, {
                    "model-value": state.password,
                    t: props.t,
                    label: props.t.passwordLabel,
                    disabled: props.disabled,
                    autocomplete: "current-password",
                    name: "password",
                    type: "password",
                    required: "",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = (value) => state.password = value)
                }, null, 8, ["model-value", "t", "label", "disabled"]),
                _createElementVNode("div", _hoisted_1, [
                    (__props.rememberMe)
                        ? (_openBlock(), _createBlock(FormCheckbox, {
                            key: 0,
                            "model-value": state.remember,
                            label: props.t.rememberLabel,
                            class: "login-form__checkbox",
                            name: "remember",
                            mode: _unref(theme) === 'strong' ? 'dark' : 'light',
                            "onUpdate:modelValue": rememberToggle
                        }, null, 8, ["model-value", "label", "mode"]))
                        : _createCommentVNode("", true),
                    (__props.isWebview)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            href: props.forgotPasswordHref,
                            class: "z-b4 f-inter"
                        }, _toDisplayString(props.t.forgotPasswordText), 9, _hoisted_2))
                        : _createCommentVNode("", true)
                ]),
                _renderSlot(_ctx.$slots, "default"),
                (props.buttonAboveForgotPass)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(ZButton, {
                            class: "login-form__button-login button__above",
                            variety: "optimus",
                            wide: "never",
                            disabled: props.disabled
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.t.loginText), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled"])
                    ]))
                    : _createCommentVNode("", true),
                (props.forgotPasswordHref && !props.isWebview)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("p", _hoisted_5, _toDisplayString(props.t.forgotPasswordText), 1),
                        _createElementVNode("a", {
                            href: props.forgotPasswordHref,
                            class: "z-b4 login-form__signup-container__forgot-password-link"
                        }, _toDisplayString(props.t.loginViaEmailText), 9, _hoisted_6)
                    ]))
                    : _createCommentVNode("", true),
                (!props.buttonAboveForgotPass)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(ZButton, {
                            class: "login-form__button-login button__below",
                            variety: "optimus",
                            wide: "never",
                            disabled: props.disabled
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.t.loginText), 1)
                            ]),
                            _: 1
                        }, 8, ["disabled"])
                    ]))
                    : _createCommentVNode("", true),
                (props.isWebview)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                        _createTextVNode(_toDisplayString(props.t.tryingToLogIn) + " ", 1),
                        _createVNode(ZButton, {
                            class: "login-form__button-send-me-a-link",
                            variety: "cyclops",
                            wide: "never",
                            href: __props.forgotPasswordHref
                        }, {
                            default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(props.t.sendMeALink), 1)
                            ]),
                            _: 1
                        }, 8, ["href"])
                    ]))
                    : _createCommentVNode("", true)
            ], 34));
        };
    }
});
